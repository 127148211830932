import Head from 'next/head';
import { type ReactElement } from 'react';

import { type Teaser } from '@replay/types/Teaser';
import { getCode, getData, type Zone } from '@replay/types/Zone';

export const getCollectionZoneFirstTeaser: (zones?: Zone[]) => Teaser | undefined = (zones = []) => {
    const predicate = (z: Zone): boolean => getCode(z).includes('collection_content');
    const contentZone = zones.find(predicate);
    const data = contentZone ? getData(contentZone) : [];
    return data[0];
};

export type DeepLinkType = 'live' | 'emac' | 'program' | 'collection' | 'search' | 'guide' | 'shorts';

const linkResolver: { [key: string]: ({ page }: { page: string; zones: Zone[] | undefined }) => string | null } = {
    live: () => 'arte://live?source=web',
    emac: ({ page }) => `arte://emac/${page}?source=web`,
    program: ({ page }) => `arte://program/${page}?source=web`,
    shorts: ({ page }) => `arte://shorts/${page}?source=web`,
    collection: ({ page, zones }) => {
        const { kind } = getCollectionZoneFirstTeaser(zones) || {};
        return (kind && kind.code && `arte://collection/${page}/${kind.code}?source=web`) || null;
    },
};

const getLinkContent = (type: DeepLinkType, page: string, zones?: Zone[]): string | null => {
    const resolver = linkResolver[type];

    return resolver ? resolver({ page, zones }) || null : null;
};

type DeepLinkProps = { url: string; page: string; zones?: Zone[]; type: DeepLinkType };

export const DeepLink = ({ url, page, zones, type }: DeepLinkProps): ReactElement => {
    const content = getLinkContent(type, page, zones);

    if (!content) {
        return <meta name="branch:deeplink:$fallback_url" content={url} />;
    }

    return (
        <Head>
            <meta key="al:ios:url" property="al:ios:url" content={content} />
            <meta key="al:android:url" property="al:android:url" content={content} />
            <meta key="twitter:app:url:iphone" name="twitter:app:url:iphone" content={content} />
            <meta key="twitter:app:url:ipad" name="twitter:app:url:ipad" content={content} />
            <meta key="twitter:app:url:googleplay" name="twitter:app:url:googleplay" content={content} />
            <meta key="branch:deeplink:$fallback_url" name="branch:deeplink:$fallback_url" content={url} />
        </Head>
    );
};
