import omit from 'ramda/src/omit';

import { type Page } from '@replay/types/Page';

export type ParentTree = Page['parent'];
type Parent = Omit<Exclude<ParentTree, null | undefined>, 'parent'>;

export const groupParentsByType = (parent: ParentTree | null | undefined): { [key: string]: Parent } => {
    if (!parent) {
        return {};
    }

    return {
        [parent.type]: omit(['parent'], parent),
        ...groupParentsByType(parent.parent as ParentTree),
    };
};

export const getCategoryFromParent = (parent?: ParentTree): Parent | undefined | null => {
    return groupParentsByType(parent).category || null;
};
