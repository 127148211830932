import { type Result } from '@replay/types/Result';
import { type ReplayError } from '@replay/types/Type_Error';
import { type Page } from '@replay/types/Page';
import { type Zone } from '@replay/types/Zone';

export type FetcherProps = {
    url: string;
    abvGroups: string | null;
    geoblocking: string | null;
};

export const getFetchOptions = (abvGroups: string | null, geoblocking: string | null) => {
    const geoblockingHeader = geoblocking ? { 'X-Country-Code': geoblocking } : null;
    const abvGroupsHeader = abvGroups ? { 'x-arte-abv': abvGroups } : null;

    return {
        method: 'GET',
        headers: {
            ...geoblockingHeader,
            ...abvGroupsHeader,
        },
    };
};

type FetchEmacData = FetcherProps;
const fetchEmacData = async <T>({ url, abvGroups, geoblocking }: FetchEmacData): Promise<Result<T, ReplayError>> => {
    if (typeof window === 'undefined') {
        const { fetchEmacFromServer } = await import('./server.ts');
        return fetchEmacFromServer<T>({ url, abvGroups, geoblocking });
    }
    const { fetchEmacFromClient } = await import('./client.ts');
    return fetchEmacFromClient<T>({ url, abvGroups, geoblocking });
};

export const fetchEmacPage = async ({
    url,
    abvGroups,
    geoblocking,
}: FetcherProps): Promise<Result<Page, ReplayError>> => {
    return fetchEmacData<Page>({ url, abvGroups, geoblocking });
};

export const fetchEmacZone = async ({
    url,
    abvGroups,
    geoblocking,
}: FetcherProps): Promise<Result<Zone['content'], ReplayError>> => {
    return fetchEmacData<Zone['content']>({ url, abvGroups, geoblocking });
};
