import { stringify, parseUrl } from 'query-string';
import nth from 'ramda/src/nth';

import { type Locale } from '@replay/i18n/types/locale';
import { getData, type Zone } from '@replay/types/Zone';
import { type ImageV3_V4 } from '@replay/types/Image';

/*
 * timestamp = 500 : the timestamp has not been filled in
 * timestamp = 501 : impossible to find an image to extract the timestamp
 * timestamp = 502 : impossible to extract the timestamp from the image
 */
export const TIMESTAMP_ERROR_NO_TIMESTAMP = 500;
export const TIMESTAMP_ERROR_NO_IMAGE = 501;
export const TIMESTAMP_ERROR_EXTRACT_FAILED = 501;

const DEFAULT_SEO_WIDTH = 1920;
const DEFAULT_SEO_HEIGHT = 1080;

type CreateArg = {
    mamiBaseUrl: string;
    locale: Locale;
    programId: string | null | undefined;
    width: number;
    height: number;
    text: boolean;
    watermark?: boolean | undefined;
    timestamp?: number;
};

export const createMamiImage = ({
    mamiBaseUrl,
    locale,
    programId,
    width,
    height,
    text,
    watermark,
    timestamp = TIMESTAMP_ERROR_NO_TIMESTAMP,
}: CreateArg): string => {
    return `${mamiBaseUrl}program/${locale}/${programId}/${width}x${height}?${stringify(
        {
            ts: timestamp,
            type: text ? 'TEXT' : null,
            watermark,
        },
        { skipNull: true },
    )}`;
};

const getFirstLandscapeImage: (zones: Zone[]) => ImageV3_V4 | undefined = (zones) => {
    const firstZone = nth(0, zones);
    if (!firstZone) {
        return undefined;
    }

    const data = getData(firstZone);
    const firstTeaser = nth(0, data);

    if (!firstTeaser) {
        return undefined;
    }

    return firstTeaser.mainImage.url;
};

type Arg = CreateArg & { zones: Zone[] };

export const getMamiImage = ({
    mamiBaseUrl,
    locale,
    programId,
    zones,
    width,
    height,
    text,
    watermark,
}: Arg): string => {
    const image = getFirstLandscapeImage(zones);

    if (!image) {
        return createMamiImage({
            mamiBaseUrl,
            locale,
            programId,
            width: DEFAULT_SEO_WIDTH,
            height: DEFAULT_SEO_HEIGHT,
            text,
            watermark,
            timestamp: TIMESTAMP_ERROR_NO_IMAGE,
        });
    }
    const baseUrl = typeof image === 'string' ? image : image.url;
    const partToReplace = typeof image === 'string' ? `__SIZE__` : `${image.w}x${image.h}`;

    const parsedUrl = parseUrl(baseUrl);

    return `${parsedUrl.url.replace(partToReplace, `${width}x${height}`)}?${stringify(
        {
            ...parsedUrl.query,
            type: text ? 'TEXT' : null,
            watermark,
        },
        { skipNull: true },
    )}`;
};
