export type Meta = {
    title: string;
    description: string;
    og: Og;
    twitter: Twitter;
};

type Seo = {
    title: string | null;
    description: string | null;
    canonical: string;
};

export type MetaV4 = {
    description: string;
    og: Og;
    title: string;
    twitter: Twitter;
    seo: Seo;
};

export const metaV4ToMeta = (metaV4: MetaV4): Meta => {
    return {
        title: metaV4.seo.title || metaV4.title,
        description: metaV4.seo.description || metaV4.description,
        og: metaV4.og,
        twitter: metaV4.twitter,
    };
};

export type Og = {
    image: OgImage;
};

export type OgImage = {
    url: string;
    width: number;
    height: number;
};

export type Twitter = {
    image: TwitterImage;
    site: string;
};

export type TwitterImage = {
    url: string;
};
